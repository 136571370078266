import React from 'react'
import { Link } from 'gatsby'
import { useSiteMetadata } from '../hooks/use-site-metadata'

export default ({ children }) => {
  const closeNav = (e) => {
    if (typeof document !== `undefined`) {
      document.body.classList.remove('nav_open')
      document.body.classList.remove('full_page_open')
    }
  }
  const { year } = useSiteMetadata()

  return (
    <header>
      <Link to="/." className="logo">
        <svg
          fill="#9c175d"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 152.4 155.5"
        >
          <path d="M108.5 115.1c-3.1-1.9-7.9-1-11.8 2.7.1-.1.2-.3.4-.4-6.1-4.4-9.6-6.8-16.9-11.9 6.8-7 10.3-10.7 17-17.7-.5-.3-1-.7-1.4-1.1 3.4 2.9 7.8 3.5 11.6 1.3 6.3-3.5 9.4-12.4 7.1-20.2-1.8-5.9-6.2-9.6-11.1-10.1-1.7-.2-3.4.1-5.1.8-1.7.7-3.2 1.8-4.4 3.3-3.4 4-4.8 10.3-3.1 16.5 0-.2-.1-.3-.2-.5-6.8 7-10.4 10.8-17.3 17.9-3.4-10.1-5.1-15.5-8.3-25.4-.1.2-.3.3-.4.4 3.9-4.5 5.4-11 4-16.4-.5-2-1.3-3.8-2.5-5.3-1.2-1.6-2.6-2.7-4.1-3.5-4.4-2.3-9.5-1.3-13.5 2.8-5.3 5.6-6.8 15.3-2.7 21.9 2.3 3.7 5.9 5.5 9.6 5.3H55c3 9.9 4.8 15.3 8.2 25.2-8.6.9-12.8 1.3-20.6 2 .1.2.1.4.2.6-2.2-5.7-6.3-9.1-10.3-9.1-1.4 0-2.8.4-4.1 1.3-1.3.9-2.4 2.1-3.2 3.6-2.4 4.3-2.9 10.2-.9 15.5 2.6 7.1 8.8 10.9 14.1 8.5 3.1-1.4 5.2-5 5.8-9.4 0 .4-.1.8-.1 1.2 7.7-.5 11.8-.8 20.2-1.6-1.7 9.7-2.4 14.2-3.5 21.9-4.5.7-7.9 4-8.2 8.2-.4 5.6 4.7 10.5 10.8 11.5 6.1.9 11-1.7 12-6.7.7-3.8-1.2-8.1-5.1-10.7 1.3-7.6 2.2-11.9 4.1-21.5 7.2 4.8 10.7 7 16.9 11 .1-.4.3-.9.4-1.3-1.2 4.1-.7 8.2 1.3 10.8 3.4 4.2 9 3.5 13.6-1.1 3.4-3.4 5.6-8.5 5.5-13.2 0-1.6-.3-3.1-1-4.4-.5-1-1.5-2-2.6-2.7" />
          <path d="M134.8 46.2c1.1 0 2.3.4 3.4 1.2 3.7 2.8 5.6 8.6 5.2 14.2-.4 4.2-2.2 7.4-4.9 8-1.4 7.8-2.3 12.1-4.5 20.8 4.9-1.2 6.9-1.9 10.4-3.3 1.3-4.7 3.1-8 4.7-8.7.2-.1.4-.1.6-.1 1.6 0 2.5 3.4 2 8.8-.6 5.9-3.3 12.7-5.8 14.5-.4.3-.7.4-1 .4-1.2 0-1.8-1.9-1.8-5-3.5 1.8-5.5 2.6-10.3 4.2.1 7.7-.1 11.5-1 18.3.3-.1.6-.2.9-.2 1.7 0 2.2 2 1.3 5.4-1.5 5.4-5.9 11.7-9.4 14.5-1.4 1.1-2.5 1.7-3.3 1.7-1.2 0-1.7-1.3-1.2-4 .6-3.3 2.9-8.3 5.8-12.2.7-6.7.8-10.4.4-18.3-5.2 6.6-8.1 9.9-13.7 16.1 0-1.6-.3-3.1-1-4.4-.6-1.3-1.6-2.3-2.7-3 6-6.6 8.9-10 14.4-16.6-4.5-4.4-7.1-6.8-13.2-12 1.3-1.2 2.5-2.8 3.5-4.7.9-1.9 1.5-3.8 1.8-5.9 6 5.3 8.5 7.8 12.9 12.4 2.3-9 3.2-13.5 4.7-21.7-2.5-3.3-4.1-9-3.5-13.7.3-4.4 2.6-6.7 5.3-6.7m0-.8c-3.3 0-5.8 2.9-6.4 7.5-.6 4.6.8 10.3 3.4 14-1.4 7.7-2.3 12.1-4.3 19.9-3.7-3.8-6.3-6.4-11.9-11.4l-1.1-1-.2 1.5c-.3 2-.9 3.9-1.7 5.6-.9 1.7-2 3.2-3.3 4.4l-.7.6.7.6c5.6 4.8 8.4 7.3 12.6 11.5-5.4 6.5-8.3 9.9-13.9 16l-.7.7.9.5c1 .6 1.8 1.5 2.4 2.7.6 1.2.9 2.5.9 4.1v2l1.4-1.5c5.2-5.7 8-9 12.4-14.5.2 6.2.1 9.8-.5 15.8-2.8 3.8-5.2 8.7-5.8 12.3-.4 2.1-.3 3.5.4 4.3.4.5.9.7 1.6.7 1 0 2.3-.6 3.8-1.9 3.5-2.8 8.1-9.1 9.7-14.9.7-2.5.7-4.4-.2-5.5-.4-.6-1.1-.9-1.8-.9.8-6.1 1-9.8.9-16.8 3.9-1.3 6-2.1 8.8-3.5.2 3.7 1.5 4.6 2.6 4.6.5 0 1-.2 1.5-.6 2.6-1.9 5.4-8.9 6.1-15.1.4-4.3 0-7.6-1.2-8.9-.4-.5-1-.7-1.6-.7-.3 0-.6.1-.9.2-2.1.9-3.9 5.1-5 8.8-2.9 1.1-4.8 1.8-8.6 2.7 1.9-7.4 2.8-11.7 4.1-19.1 2.7-1 4.6-4.2 5-8.6.5-6.1-1.8-12.1-5.5-14.9-1.3-.7-2.6-1.2-3.9-1.2" />
          <path d="M40.8 18.1c3.4 0 5.1 3.5 2.9 9.3-1.8 4.3-5.6 8.4-9.1 9.9-2.9 8.1-4.4 12.7-7 22.2 6.1-1.9 9.5-2.7 16.9-4.2-.6 1.9-1 4.1-1 6.2 0 2.2.3 4.2 1 6.1-7.6 1.4-11.2 2.2-17.4 3.9 1.7 9 2.8 13.7 5.6 23.1-1.4 0-2.8.4-4.1 1.3-1.3.9-2.4 2.1-3.2 3.6-2.6-9-3.6-13.5-5.2-22.2-4.5 7.1-6.5 10.7-9.7 17 .8 5 .3 10.7-1.2 13.9-.9 2-2 2.9-3.1 2.9-1.3 0-2.6-1.3-3.7-3.6C.6 103.2.1 96.2 2 90.6c1-3 2.4-4.6 3.9-4.6.5 0 1.1.2 1.6.7 3.1-6.7 5.1-10.4 9.4-17.6-3.6-3.9-5-5.5-7.1-8.7-1.7 2.9-3.3 4.5-4.4 4.5-.4 0-.7-.2-1-.7-1.1-2.4.8-10 4-16.8 2.6-5.5 5.1-8.7 6.5-8.7.3 0 .6.2.8.5.7 1.4.2 5.7-1.4 10.9 2.1 2.9 3.5 4.5 7.2 8.2 2.7-9.3 4.2-13.8 7.3-21.7-1.7-2.1-1.2-6.1.9-10 2.9-5.2 7.4-8.3 10.9-8.5h.2m0-.8h-.3c-4 .2-8.7 3.8-11.6 8.9-2.3 4.1-2.7 8.1-1.2 10.5-2.9 7.4-4.4 11.8-6.8 20-2.9-2.9-4.2-4.4-5.9-6.8.7-2.4 2.5-8.8 1.3-11.1-.4-.8-1.1-1-1.5-1-2.5 0-5.6 5.8-7.2 9.2-3 6.3-5.4 14.6-4 17.5.5 1 1.3 1.2 1.7 1.2 1.5 0 3.2-1.9 4.4-3.9 1.8 2.5 3.3 4.3 6.1 7.3-3.9 6.6-5.9 10.2-8.7 16.3-.4-.2-.8-.3-1.2-.3-1.9 0-3.5 1.9-4.6 5.2-1.8 5.4-1.6 12.7.5 17.4 1.2 2.7 2.8 4.1 4.4 4.1 1 0 2.5-.6 3.8-3.3 1.5-3.3 2.1-9 1.3-14.1 2.8-5.5 4.7-8.9 8.4-15 1.3 7.4 2.4 12 4.8 20.2l.5 1.8 1-1.7c.8-1.4 1.8-2.5 3-3.3 1.1-.8 2.4-1.2 3.7-1.2h1.1l-.3-1c-2.8-9-3.9-13.8-5.5-22.2 5.8-1.6 9.4-2.3 16.6-3.6l.9-.2-.3-.9c-.6-1.7-.9-3.5-.9-5.4v-.5c0-2 .3-4.1 1-6l.4-1.3-1.4.3c-6.8 1.4-10.3 2.2-15.6 3.8 2.2-8.2 3.6-12.7 6.4-20.4 3.7-1.7 7.5-5.9 9.2-10.2 1.4-3.5 1.4-6.6.1-8.5-.7-1.1-2-1.8-3.6-1.8" />
          <path d="M86 .8c1.3 0 2.7.1 4.2.4 6.1 1.1 11.8 4.7 11.9 8 .1 2.3-2.7 3.8-6.8 3.8h-.6c-.4 4.8-.8 7.6-1.9 14.2 8 .4 12 .8 19.4 2 .6-2.7 2.7-4.2 5.6-4.2.8 0 1.6.1 2.5.3 5.7 1.4 10.5 6.8 11.7 12.2 1 5-1.3 9.1-6 9.1-.3 0-.7 0-1.1-.1-4-.5-8.4-4.1-10.9-8.4-7.4-.9-11.4-1.2-19.6-1.4 3.7 7.9 5.6 12.2 8.9 21.3-.4 0-.7-.1-1.1-.1-1.3 0-2.7.3-4 .8-1.7.7-3.2 1.8-4.4 3.3-3.4-9.5-5.2-14-8.7-22.2-6.3 5.2-9.7 8.2-16.5 14.7-.5-2-1.3-3.8-2.5-5.3-1.2-1.6-2.6-2.7-4.1-3.5 6.6-6.2 9.9-9 16.1-13.9-7.1-4.3-10.6-6.2-16.6-9.2-2.3 1.6-5.2 2.4-7.8 2.4-1.5 0-2.8-.3-4-.9-4.2-2.3-3.8-7.2-.5-10.5 2.7-2.7 6.4-4.4 10.1-4.4.8 0 1.6.1 2.4.3 3.2.8 5.2 3.4 4.6 6.7 6.1 2.6 9.5 4.3 16.7 8.3 1.3-6.5 1.8-9.2 2.4-13.7-4-2-6.2-4.6-6-6.6C79.7 2 82.2.8 86 .8m0-.8v.8V0c-4.3 0-7.1 1.4-7.4 3.9-.3 2.3 2 5 5.9 7.2-.6 4-1 6.6-2.1 12-6.5-3.6-9.9-5.2-15.2-7.5.3-3.3-1.7-6.1-5.3-7-.8-.2-1.7-.3-2.6-.3-3.7 0-7.7 1.7-10.7 4.7-2.1 2.1-3.1 4.8-2.7 7.2.3 2 1.5 3.6 3.4 4.6 1.2.6 2.7 1 4.4 1 2.6 0 5.4-.8 7.8-2.3 5.5 2.8 8.9 4.6 15.2 8.4-5.8 4.7-9.1 7.5-15.2 13.2l-.8.8 1 .5c1.5.7 2.7 1.8 3.8 3.3 1.1 1.4 1.9 3.1 2.3 5l.3 1.4 1-1c6.5-6.2 9.9-9.2 15.7-14 3.1 7.3 4.9 11.8 8.3 21.2l.5 1.3.9-1.1c1.2-1.4 2.6-2.4 4.1-3 1.2-.5 2.4-.8 3.7-.8.3 0 .7 0 1 .1l1.3.1-.4-1.2c-3.1-8.5-4.9-12.9-8.4-20.2 7.1.3 11.1.5 17.8 1.3 2.7 4.6 7.2 7.9 11.2 8.5.4 0 .8.1 1.2.1 2.2 0 4.1-.8 5.3-2.4 1.5-1.9 2-4.7 1.4-7.7-1.2-5.8-6.5-11.3-12.2-12.8-.9-.2-1.9-.4-2.7-.4-3 0-5.3 1.5-6.2 4.1-6.7-1-10.7-1.4-17.8-1.8 1-5.7 1.3-8.5 1.7-12.6 2.9 0 5.1-.8 6.4-2 .7-.7 1.1-1.6 1-2.6-.1-4.1-6.6-7.7-12.5-8.8C88.8.1 87.3 0 86 0" />
        </svg>
      </Link>
      <div className="title">
        <a href="/."> Rapport annuel {year} </a>
        </div>
      {children}
    </header>
  )
}
